import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import get from 'lodash/get'
import moment from 'moment'
import { Heading, Chip, Loader, Button } from '@mlcl-digital/mlcl-design'
import Container from '@mlcl-digital/mlcl-design/lib/base/Container'
import GridContainer from '@mlcl-digital/mlcl-design/lib/base/GridContainer'
import LabelValuePair from '@mlcl-digital/mlcl-design/lib/base/LabelValuePair'
import ActionLink from '@mlcl-digital/mlcl-design/lib/base/ActionLink'
import InlineMessage from '@mlcl-digital/mlcl-design/lib/base/InlineMessage'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'

// constants
import {
  POLICY_IDENTIFIER_POLICY_ID,
  JURISDICTIONS,
  POLICY_STATUS_NA,
  POLICY_STATE_INFORCE,
  POLICY_CHILD_COVER_BENEFIT_CODE,
  POLICY_FREQUENCY_MONTHLY,
} from '../../../../../constants/policies'
import { PAYMENT_FREQUENCY_OPTIONS } from '../../../../../constants/forms'

// FIXME: ts-expect-error comment can be removed when files are converted to typescript
// utils
import {
  getPolicyTypeLabel,
  getPolicyOwner,
  getPolicyOwnerName,
  getAllLifeInsured,
  generateCOCPayload,
  getPolicyStatus,
  isBenefitAssuredForLifeInsured,
  isBenefitInForce,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/policyUtils'
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { dollarAmountWithCommasAndTwoDecimal } from '../../../../../utils/formUtils'
import { getCollectionFrequencyUnit } from '../../../../../utils/paymentUtils'
// @ts-expect-error file not in typescript
import { DATE_FORMAT_WITH_MONTH_NAME } from '../../../../../utils/quoteUtils'
// @ts-expect-error file not in typescript
import { downloadDocument } from '../../../../../utils/downloadDocumentUtils'
import {
  getPaymentStatus,
  isPolicyLapseDateOverThanCertainDays,
  // @ts-expect-error file not in typescript
} from '../../../../../utils/customerOneTimePaymentUtils'
import { createEvent } from '../../../../../utils/telemetry'

// components
import PolicyAdditionalDetails from '../PolicyAdditionalDetails'

// selectors
import { getMasterData } from '../../../../../selectors/common.selectors'

// styles
import styles from './policy.styles'

// types
import { ClientPolicy } from '../../../../../types/ClientPolicies'
import { fieldsType } from '../../../../../types/components/AdvisorClientDetails'
import { Store } from '../../../../../types/store'

const Wrapper = styled('div')(styles.wrapper)
const Header = styled('div')(styles.header)
const PolicyTypeContainer = styled('div')(styles.policyTypeContainer)
// @ts-expect-error getting typescript error because of emotion
const PremiumTooltipContainer = styled('div')(styles.premiumTooltipContainer)
const CTAWrapper = styled('div')(styles.ctaWrapper)
const StyledButton = styled(Button)(styles.button)
const DownloadCTA = styled(StyledButton)(styles.downloadCTA)
const DownloadIcon = styled(Icon)(styles.downloadIcon)
const Spinner = styled(Loader)(styles.spinner)
const HeadingWrapper = styled('div')(styles.headingWrapper)

type policyProps = {
  policyData: ClientPolicy
  fields: fieldsType
  policies: Array<ClientPolicy>
  onPolicyTabChange: (index: number) => void
}
type lifeInsuredType = {
  bancsCustomerNo: string
  relatedParty: {
    firstName: string
    lastName: string
    gender: string
    isSmoker: boolean
    dateOfBirth: string
  }
}
type policyOwnerType = {
  relatedParty: {
    partyType: string
    businessName?: string
    title?: string
    firstName?: string
    middleName?: string
    lastName?: string
  }
  bancsCustomerNo: string
}
const Policy = ({ policyData, fields, policies, onPolicyTabChange }: policyProps) => {
  const { policyStatus, benefitStatus } = useSelector(getMasterData)
  const config = useSelector((state: Store) => state.config)
  const [isCoCLoading, setCoCLoading] = useState(false)
  const {
    policy: {
      productClass,
      identifiers = [],
      relationships,
      policyName,
      policyPremium,
      paymentDetails,
      policyPremiumFrequency,
      startDate,
      policyExpiry,
      jurisdiction,
      policyFee,
      stampDuty,
      status = '',
      benefits,
      outstandingBalance = 0,
    },
  } = policyData

  const [showLifeInsured, setToggleLifeInsured] = useState(false)

  useEffect(() => {
    if (showLifeInsured) {
      const event = createEvent({
        GA: {
          category: 'Show more policy details',
          action: 'Select',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Show more policy details',
          },
        },
      })
      event.end()
    }
  }, [showLifeInsured])

  const currentStatus = getPolicyStatus(status, policyStatus) as string
  const isPolicyOutOfForce = currentStatus !== POLICY_STATUS_NA

  // function to call download document
  const downloadCertificateOfCurrency = () => {
    setCoCLoading(true)
    const requestPayload = generateCOCPayload(policyData.policy, policies, benefitStatus)

    const event = createEvent({
      GA: {
        category: 'Downloading certificate of currency',
        action: 'Download',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'Downloading certificate of currency',
        },
      },
    })

    downloadDocument(requestPayload, null, null, config, () => {
      event.end()
      setCoCLoading(false)
    })
  }

  const renderLifeInsuredSection = () => {
    const lifeInsuredList = (getAllLifeInsured(relationships, false) as lifeInsuredType[])
      .filter(
        lifeInsured =>
          // We don't show Life Insured that are for Child CI benefits due to legal reasons
          !benefits.some(
            benefit =>
              isBenefitAssuredForLifeInsured(benefit, lifeInsured.bancsCustomerNo) &&
              benefit.type === POLICY_CHILD_COVER_BENEFIT_CODE
          )
      )
      // showing list of life insured for only active benefits in case of inforce policy
      .filter(
        lifeInsured =>
          isPolicyOutOfForce ||
          benefits.some(
            benefit =>
              isBenefitAssuredForLifeInsured(benefit, lifeInsured.bancsCustomerNo) &&
              isBenefitInForce(benefit, policyStatus)
          )
      )
    return lifeInsuredList.map((lifeInsured, index) => {
      const {
        relatedParty: { firstName, lastName, gender, isSmoker, dateOfBirth },
      } = lifeInsured
      return (
        <>
          <Heading variant="h4" size="small" data-testid="policyLifeInsured">
            {renderTextField(fields.LifeInsured)}
            {lifeInsuredList.length > 1 ? ` ${index + 1}` : ''}
          </Heading>
          <GridContainer>
            <LabelValuePair
              label={renderTextField(fields.FullName)}
              value={`${firstName} ${lastName}`}
            />
            <LabelValuePair
              label={renderTextField(fields.DateOfBirth)}
              value={dateOfBirth && moment(dateOfBirth).format(DATE_FORMAT_WITH_MONTH_NAME)}
            />
            <LabelValuePair label={renderTextField(fields.Gender)} value={gender} />
            <LabelValuePair label={renderTextField(fields.SmokerStatus)} value={isSmoker} />
          </GridContainer>
        </>
      )
    })
  }

  const renderPremiumInfo = () => {
    const { PremiumBreakdown, Premium, PolicySetup, StampDuty } = fields
    const premiumInfo = [
      [Premium, dollarAmountWithCommasAndTwoDecimal(policyPremium)],
      [PolicySetup, dollarAmountWithCommasAndTwoDecimal(policyFee)],
      [StampDuty, stampDuty && dollarAmountWithCommasAndTwoDecimal(stampDuty)],
    ]
    const premiumFrequency = (
      PAYMENT_FREQUENCY_OPTIONS.find(frequency => frequency.value === policyPremiumFrequency) || {}
    ).label
    return (
      <PremiumTooltipContainer>
        <div>{get(PremiumBreakdown, 'value', '').replace('{0}', premiumFrequency as string)}</div>
        {premiumInfo.map(premiumField =>
          premiumField[1] ? (
            <div>
              {renderTextField(premiumField[0])} {premiumField[1]}
            </div>
          ) : null
        )}
      </PremiumTooltipContainer>
    )
  }

  const policyType = getPolicyTypeLabel(productClass)
  const policyID = get(
    identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID),
    'value'
  )

  const policyOwners = getPolicyOwner(relationships, true) as policyOwnerType[] | policyOwnerType
  const policyOwnerPersonnel = Array.isArray(policyOwners)
    ? policyOwners.map(getPolicyOwnerName).join(', ')
    : (getPolicyOwnerName(policyOwners) as string)

  const collectionFrequency = getCollectionFrequencyUnit(
    get(paymentDetails, 'collectionFrequency', policyPremiumFrequency)
  )
  const isPolicyFullyInforce = status === POLICY_STATE_INFORCE
  const nextCollectionDate =
    paymentDetails?.nextCollectionDate ?? (get(benefits, '[0].nextPremiumDueDate', 'N/A') as string)
  const policyBenefitStatuses =
    benefits &&
    benefits.map(benefit => ({
      status: benefit.benefitStatus,
      statusDate: benefit.benefitStatusDate,
    }))
  const hasPolicyLapsedWithGracePerod = getPaymentStatus(
    status,
    isPolicyLapseDateOverThanCertainDays(policyBenefitStatuses, 30)
  )
  const formattedPremiumDueDate =
    nextCollectionDate === 'N/A'
      ? nextCollectionDate
      : moment(nextCollectionDate, 'YYYY-MM-DD').format('DD MMM YYYY')

  const revivableLapseDueDate =
    status === 'Revivable Lapse'
      ? moment(benefits[0]?.benefitStatusDate, 'YYYY-MM-DD').add(30, 'days').format('DD MMM YYYY')
      : ''

  // RET-20606
  // We need to hide premium due date for non-monthly collected premiums
  // due to the them frequently being incorrect when the balance is outstanding
  const shouldHidePremiumDueDate = policyPremiumFrequency !== POLICY_FREQUENCY_MONTHLY

  return (
    <Wrapper>
      <Container>
        <Header>
          <PolicyTypeContainer>
            <Chip variant={policyType === 'Inside Super' ? 'default' : 'important'}>
              {policyType}
            </Chip>
            {isPolicyOutOfForce && <Chip variant="removed">{currentStatus}</Chip>}
          </PolicyTypeContainer>
          {isPolicyFullyInforce && (
            <CTAWrapper>
              {/* RET-20119 : Functionality will be built in future
              <DownloadCTA
                label={renderTextField(fields.PolicySummary)}
                iconName={['far', 'download']}
          /> */}
              <DownloadCTA variant="link" onClick={() => downloadCertificateOfCurrency()}>
                {isCoCLoading ? (
                  <Spinner spinnerSize={Number(20)} borderSize={Number(2)}>
                    {renderTextField(fields.CertificateOfCurrency)}
                  </Spinner>
                ) : (
                  <DownloadIcon iconName={['far', 'download']} />
                )}
                {renderTextField(fields.CertificateOfCurrency)}
              </DownloadCTA>
            </CTAWrapper>
          )}
          <HeadingWrapper>
            <Heading variant="h2" size="medium">
              {renderTextField(fields.Policy)} {policyID}
            </Heading>
            {!isPolicyOutOfForce &&
              (outstandingBalance > 0 ? (
                <InlineMessage
                  iconName={['far', 'shield-exclamation']}
                  message={`${dollarAmountWithCommasAndTwoDecimal(outstandingBalance)} ${
                    shouldHidePremiumDueDate
                      ? 'outstanding premium balance'
                      : (get(fields, 'OutstandingPremium.value', '') as string)
                  } ${shouldHidePremiumDueDate ? '' : formattedPremiumDueDate}`}
                />
              ) : (
                <InlineMessage
                  message={`${
                    get(fields, 'NextPremiumDueDate.value', '') as string
                  } ${formattedPremiumDueDate}`}
                />
              ))}
            {hasPolicyLapsedWithGracePerod && outstandingBalance > 0 && (
              <InlineMessage
                iconName={['far', 'shield-exclamation']}
                message={`${dollarAmountWithCommasAndTwoDecimal(outstandingBalance)} ${
                  get(fields, 'OutstandingPremium.value', '') as string
                } ${revivableLapseDueDate}, ${
                  get(fields, 'OutstandingPremiumRevivable.value', '') as string
                }`}
              />
            )}
          </HeadingWrapper>
        </Header>
        <GridContainer>
          <LabelValuePair
            label={renderTextField(fields.PolicyOwner)}
            value={policyOwnerPersonnel}
          />
          <LabelValuePair label={renderTextField(fields.ProductName)} value={policyName || '-'} />
          {!isPolicyOutOfForce && (
            <LabelValuePair
              label={renderTextField(fields.Premium)}
              value={`${dollarAmountWithCommasAndTwoDecimal(policyPremium)}/${collectionFrequency}`}
              tooltipContent={renderPremiumInfo()}
            />
          )}
          <LabelValuePair
            label={renderTextField(fields.StartDate)}
            value={startDate && moment(startDate).format(DATE_FORMAT_WITH_MONTH_NAME)}
          />
          {!isPolicyOutOfForce && (
            <LabelValuePair
              label={renderTextField(fields.EndDate)}
              value={policyExpiry && moment(policyExpiry).format(DATE_FORMAT_WITH_MONTH_NAME)}
            />
          )}
          <LabelValuePair
            label={renderTextField(fields.StateRegister)}
            value={JURISDICTIONS[jurisdiction]}
          />
        </GridContainer>
        {showLifeInsured && renderLifeInsuredSection()}
        <ActionLink
          onClick={() => setToggleLifeInsured(!showLifeInsured)}
          label={get(showLifeInsured ? fields.ShowLess : fields.ShowMore, 'value', '')}
        />
      </Container>
      <PolicyAdditionalDetails
        policyData={policyData}
        fields={fields}
        onPolicyTabChange={onPolicyTabChange}
      />
    </Wrapper>
  )
}

export default Policy
