/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useMemo } from 'react'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mlcl-digital/mlcl-design/lib/base/Table'
import FilterBar from '@mlcl-digital/mlcl-design/lib/base/FilterBar'
import { Loader, ErrorState } from '@mlcl-digital/mlcl-design'
import styled from '@emotion/styled'
import moment from 'moment'

// theme
import { themeConsumer } from '../../../../../styles/ThemeContext'

// Redux
// @ts-ignore
import { actionCreators } from '../../../../../actions'
import { getClientCorrespondencyHistory } from '../../../../../selectors/clientCorrespondence'
// Types
import { Store } from '../../../../../types/store'
import { ClientPolicy } from '../../../../../types/ClientPolicies'
import { SitecoreField } from '../../../../../types/sitecore'

// Constants
// @ts-ignore
import { POLICY_IDENTIFIER_POLICY_ID } from '../../../../../constants/policies'

// Utils
// @ts-ignore
import { downloadDocument } from '../../../../../utils/downloadDocumentUtils'
// @ts-ignore
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { createEvent } from '../../../../../utils/telemetry'

import styles from './correspondence.styles'

const FilterBarContainer = styled('div')(styles.filterContainer)
const DownloadLink = styled('a')(styles.anchor)
const StateContainer = styled('div')(styles.stateContainer)
const Info = styled('p')(styles.info)
const Spinner = styled(Loader)(styles.spinner)

const channel = 'Outbound'

type Fields = {
  correspondenceHistoryHeaderDate?: SitecoreField
  correspondenceHistoryHeaderPolicyNumber?: SitecoreField
  correspondenceHistoryHeaderDescription?: SitecoreField
  correspondenceHistoryNoRecordMessage?: SitecoreField
  SearchCorrespondenceLabel?: SitecoreField
  AppActivityMessage1?: SitecoreField
}
type props = {
  policyData: ClientPolicy
  fields: Fields
  theme: {
    button: object
  }
}

const columns = ({
  correspondenceHistoryHeaderDate = { value: '' },
  correspondenceHistoryHeaderDescription = { value: '' },
}: Fields) => [
  {
    Header: correspondenceHistoryHeaderDate.value,
    accessor: 'date',
  },
  {
    Header: correspondenceHistoryHeaderDescription.value,
    accessor: 'description',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'downloadLink',
    disableSortBy: true,
  },
]

const analytics = {
  category: (description: string) =>
    `Downloading past correspondence - documents tab - ${description}`,
  action: 'Download',
}

const Correspondence = ({ policyData, fields = {}, theme }: props) => {
  const {
    policy: { identifiers },
  } = policyData

  const dispatch = useDispatch()
  const COLUMNS = columns(fields)

  const [loadingRowState, setLoadingRowState] = useState<Array<number>>([])
  const [recordsToShow, setShowingRecords] = useState<
    Array<{
      date: string
      description: string
      externalId: string
      isDownloading: boolean
    }>
  >([])

  const [search, setSearch] = useState('')
  const [dateRange, setDateRange] = useState<{ to: Date | null; from: Date | null }>({
    to: null,
    from: null,
  })
  const [selection, setSelection] = useState('')

  const correspondenceHistory = useSelector(getClientCorrespondencyHistory)
  const config = useSelector((state: Store) => state.config)
  const { records } = correspondenceHistory

  const mapRecordsToState = useMemo(
    () =>
      recordsToShow.map((record, index) => ({
        ...record,
        downloadLink: (
          <DownloadLink
            onClick={() => {
              record.isDownloading = true
              setLoadingRowState([...loadingRowState, index])
              const event = createEvent({
                GA: {
                  category: analytics.category(record.description),
                  action: analytics.action,
                },
                Splunk: {
                  attributes: {
                    'workflow.name': analytics.category(record.description),
                  },
                },
              })
              downloadDocument({}, 'GET', `/documents/${record.externalId}`, config, () => {
                event.end()
                record.isDownloading = false
                setLoadingRowState(
                  loadingRowState.filter(i => i !== index && record.isDownloading === true)
                )
              })
            }}
          >
            {loadingRowState.includes(index) ? (
              <Spinner
                type="noHeight"
                loaderContent="Downloading"
                borderSize={2}
                spinnerSize={16}
                textPosition="horizontal"
              />
            ) : (
              'Download'
            )}
          </DownloadLink>
        ),
      })),
    [recordsToShow, loadingRowState]
  )

  useEffect(() => {
    setShowingRecords(records)
  }, [records])

  useEffect(() => {
    let filteredRecords = records
    if (selection) {
      if (selection !== 'All') {
        filteredRecords = filteredRecords.filter(record => record.description === selection)
      }
    }
    if (search) {
      filteredRecords = filteredRecords.filter(record =>
        record.description.toLowerCase().includes(search.toLowerCase())
      )
    }
    if (dateRange.to || dateRange.from) {
      const dateString = 'DD MMM YYYY'
      if (dateRange.to && dateRange.from) {
        filteredRecords = filteredRecords.filter(
          item =>
            moment(item.date, dateString).isBetween(dateRange.from, dateRange.to) ||
            moment(item.date, dateString).isSame(dateRange.from) ||
            moment(item.date, dateString).isSame(dateRange.to)
        )
      } else if (dateRange.from) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.date, dateString).isSameOrAfter(dateRange.from)
        )
      } else if (dateRange.to) {
        filteredRecords = filteredRecords.filter(item =>
          moment(item.date, dateString).isSameOrBefore(dateRange.to)
        )
      }
    }
    if (search || dateRange.to || dateRange.from || selection) {
      const event = createEvent({
        GA: {
          category: 'Filter correspondence',
          action: 'View',
        },
        Splunk: {
          attributes: {
            'workflow.name': 'Filter correspondence',
          },
        },
      })
      event.end()
    }
    setShowingRecords(filteredRecords)
  }, [search, dateRange, selection])

  useEffect(() => {
    const policyID = get(
      identifiers.find(item => item.type === POLICY_IDENTIFIER_POLICY_ID),
      'value'
    )
    const event = createEvent({
      GA: {
        category: 'Advisor client policy details',
        action: 'View correspondence',
      },
      Splunk: {
        attributes: {
          'workflow.name': 'View correspondence',
        },
      },
    })
    event.end()
    // @FIXME: hot module replacement means there's no types here, not sure how to fix
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(actionCreators.clearCorrespondenceHistory())
    dispatch(
      // @FIXME: hot module replacement means there's no types here, not sure how to fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.getCorrespondenceHistory({
        externalPolicyNumber: [policyID],
        channel,
      })
    )
  }, [identifiers])

  const descriptions = new Set(mapRecordsToState.map(record => record.description))

  return (
    <>
      {correspondenceHistory.isSending && <Loader />}
      {records.length === 0 &&
        !correspondenceHistory.isSending &&
        !correspondenceHistory.isError && (
          <StateContainer>
            <ErrorState
              emptyState
              showBackground={false}
              message={renderTextField(fields.correspondenceHistoryNoRecordMessage)}
            />
          </StateContainer>
        )}
      {correspondenceHistory.isError && (
        <StateContainer>
          <ErrorState showBackground={false} />
        </StateContainer>
      )}
      {records.length > 0 && (
        <>
          <FilterBarContainer>
            <FilterBar
              searchPlaceholder={get(fields, 'SearchCorrespondenceLabel.value', '') as string}
              onSearch={e => setSearch(e.value)}
              onDateRange={e => setDateRange(e)}
              selectOptions={[
                { label: 'All', value: 'All' },
                ...[...Array.from(descriptions).sort()].map((description: string) => ({
                  value: description,
                  label: description,
                })),
              ]}
              onSelection={e => setSelection(e)}
              showClearAll
            />
          </FilterBarContainer>
          <Info variant="linkUnderlineOnHover" theme={theme}>
            {renderTextField(fields.AppActivityMessage1, true)}
          </Info>
          <Table
            pageSize={20}
            data={mapRecordsToState}
            columns={COLUMNS}
            styleOverrides={{
              cell: styles.cell,
              footer: styles.tableFooter,
              headerCell: styles.cell,
            }}
          />
        </>
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default themeConsumer(Correspondence)
